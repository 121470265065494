import React from 'react';
import { client, URL } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const OrderList = React.lazy(() => import('@izi-logistics/common/lib/components/Order/List/Customer/List'));

export default function CarrierOrderList(props) {
  return (
    <PreloadingComponent>
      <OrderList {...props} config={{ URL, client }} />
    </PreloadingComponent>
  );
}
